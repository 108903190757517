@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* ::-webkit-scrollbar {
  width: 8px;
}

* ::-webkit-scrollbar-thumb {
  background: #949494;
}

* ::-webkit-scrollbar-track {
  background: #fefefe;
}

.blacked {
  fill: #5935a4;
  stroke-width: 2px;
}

.blacked * {
  fill: #5935a4;
}

/* discord styles from devjams */
.discord-bg--light {
  line-height: 56px;
}

.discord-link {
  width: 350%;
  height: 100%;
}

.discord-bg--light::before {
  content: "";
  background-color: #ff7f4d;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.discord-bg--light::after {
  content: "";
  background-color: #ff7f4d;
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
